export default [
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'Jane Doe',
    customerTitle: 'Director of Research and Data',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'John Doe',
    customerTitle: 'Director of Research and Data',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'Jane Smith',
    customerTitle: 'Director of Research and Data',
    customerImage: 'https://placeimg.com/150/150/people'
  }
];
